import * as React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";

const TermsOfService = () => {
	return (
		<Layout id={"content-page"}>
			<Helmet>
				<title>SG-1.online - Terms of Service</title>
			</Helmet>
			<main>
				<section className='content-section'>
					<div className={"container"}>
						<div className={"flex items-left h-full"}>
							<div className={"basis-full text-left"}>
								<h1 className='h2 title'>Terms &amp; Conditions</h1>
								<div className='page__content'>
									<p className='h4 title'>
										<b>1. Applicability&nbsp;</b>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											These General Terms and Conditions, any documents to which is a link provided herein and any policies
											(referred to as “Terms” or “Terms and Conditions” hereinafter) apply to all Services through the SG-1
											Validators (ToschDev) Website
										</span>{" "}
										<a href='http://www.sg-1.online/'>
											<span style={{ fontWeight: "400px" }}>www.sg-1.online</span>
										</a>
										<span style={{ fontWeight: "400px" }}>
											{" "}
											and all of its subdomains as well as other Services provided and/or domains registered by Toschdev &amp;
											SG-1. You hereby confirm that you are able to speak and understand the English language and accept these
											Terms and Conditions in the English language.
										</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											Please read these Terms and Conditions carefully before using any of the SG-1 Validators Services or the
											SG-1 Validators Websites or subdomains. By using any of Our Services and/or Our Website You state that You
											have read, understood and accepted these Terms.&nbsp;
										</span>
									</p>
									<p className='description'>&nbsp;</p>
									<p className='h4 title'>
										<b>1.1. Definitions</b>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											The terms “We”, “Us”, “Our”, “SG-1” and “SG-1 Validators” shall refer to the company Toschdev
											hereinafter.&nbsp;
										</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											The terms “Our Website”, “Website”, “SG-1 Validators Website” and “sg-1.online” refers to the website{" "}
										</span>
										<a href='http://www.sg-1.online/'>
											<span style={{ fontWeight: "400px" }}>www.sg-1.online</span>
										</a>
										<span style={{ fontWeight: "400px" }}>
											{" "}
											and all of its subdomains as well as any other domains registered by Toschdev.
										</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											The terms “Service”, “Services”, “Our Service” and “Our Services” refer to all services provided by SG-1
											Validators including but not limited to Our Website, Our subdomains, Our infrastructure services, Our
											bots, Our code, Our information, Our marketing material, etc.
										</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											The terms “You”, “Your”, “Customer” and “User” refer to the identified or identifiable natural person
											using all or parts of Our Services or Our Website.
										</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											The term “Staking” or “Delegating” refers to the action of locking a certain amount of Cryptocurrencies
											with a Validator (for a certain amount of time) in order to economically secure the Blockchain network.
										</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											The term “Stake” or “Delegation” refers to the amount of Staked or Delegated Cryptocurrencies.
										</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											The term “Staker” or “Delegator” refers to the entity which is Staking or Delegating.
										</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>The term “Blockchain” refers to </span>
										<span style={{ fontWeight: "400px" }}>
											a digital, shared, immutable ledger that is distributed over multiple independent clients and facilitates
											the process of recording transactions and tracking assets in a network.
										</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											The term “Validator” refers to nodes that contribute to the consensus of a Blockchain. Validators are
											randomly selected to propose a block and require a certain amount of Staked assets to be eligible to
											contribute to a Blockchain network.
										</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											The term “IBC-Relayer” or “Relayer” refers to an entity or process that actively engages in relaying
											cross-blockchain information between different Blockchain networks.
										</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											The term “Cryptocurrency” refers to a digital currency and/or digital tokens which are calculated by a
											Blockchain network based on cryptographic proofs.
										</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											The term “Reward” refers to the return of investment of a specific Cryptocurrency which Stakers who are
											eligible receive for Staking or Delegating that Cryptocurrency to a Validator. Rewards are never paid out
											by the Validator, but rather by the Blockchain’s distribution module.
										</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											The term “Slashing” refers to the punishment in form of deducted and annihilated percentage of Delegated
											Cryptocurrencies, in case a Validator becomes inactive or misbehaves according to the ruleset of Byzantine
											Fault Tolerance.
										</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											The term “Jailing” refers to the action of removing a Validator from the set of active Validators of a
											Blockchain network due to downtime or misbehaviour in terms of the Blockchain ruleset. Jailing is always
											carried out by the Blockchain itself, which monitors Validator liveliness and behaviour.&nbsp; A Jailed
											Valiadator does not accrue any Staking Rewards.
										</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											The term “Governance” refers to an on-chain process which allows Stakers or Delegators to govern a
											Blockchain network and meet proposed decisions on protocol-level. The respective voting power of a
											Delegator correlates to the ratio of Delegated Cryptocurrencies. Validators are eligible to vote with
											their full Delegated voting power.
										</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											The term “Governance Proposal” or “Proposal” refers to an on-chain proposal which uses a respective
											on-chain Governance module. Within a defined voting period Validators as well as Stakers or Delegators can
											actively vote for or against a proposed change.&nbsp;
										</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											The term “Unbonding” or “Undelegating” refers to the action of releasing Cryptocurrencies from a locked
											state with the Validator as a Stake.
										</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											The term “Unbonding Time” refers to the time Cryptocurrencies stay locked with the Validator after the
											Customer has Undelegated (“Unbonded”) his Cryptocurrencies until they are no longer locked. The Unbonding
											Time is determined by the specific Blockchain’s ruleset.
										</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											By using Our Services and Our Website You indicate that You consent to these General Terms and Conditions
											and Our Privacy Policy. By using Our Services and Our Website You also confirm that You are of the
											required age in Your country of jurisdiction to enter into legally binding contracts and are competent to
											enter into legally binding contracts. By using Our Services and Our Website You also confirm that You are
											legally allowed to use Our Services by law or regulation in Your country of jurisdiction and that Your
											usage does not require any registration by Us within Your country or jurisdiction. By using Our Services
											and/or Our Website You are solely responsible for acting according to Your local laws. We have reserved
											the right to cancel Our Services if We have reason to believe that You do not fulfil any requirements
											mentioned herein.
										</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											If You have any questions concerning Our General Terms and Conditions, Our Privacy Policy or Our Services,
											please contact Us via email:{" "}
										</span>
										<a href='mailto:support@sg-1.online'>
											<span style={{ fontWeight: "400px" }}>support@sg-1.online</span>
										</a>
										<span style={{ fontWeight: "400px" }}>&nbsp;</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											We reserve the right to amend the Terms at any time without further notice, these changes will be
											effective from the publication of the new Terms on the SG-1 Validators Website. When You use Our Services
											and/or Our Website, the contract between You and Us will be subject to the current version of these Terms
											as published on Our Website. If these Terms are not available on Our Website for any reason, the last
											published version of these Terms shall apply.
										</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											If You do not comply with Our Terms and Conditions or Our Privacy Policy, do not use Our Website and/or
											Our Services. We reserve the right to change, modify, amend as well as add or remove parts of these Terms
											at any time without further notice. Please check Our Terms and Conditions and Our Privacy Policy
											frequently for any changes before using it. If You continue using Our Website and/or Our Services after
											published updates You thereby accept and agree to the current Terms which are on Our Website.
										</span>
									</p>
									<p className='description'>&nbsp;</p>
									<p className='h4 title'>
										<b>2. Services &amp; Website</b>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>2.1. Blockchain Validation</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											SG-1 Validators provide, operate and maintain Validator Nodes in the form of hardware and software to
											contribute to the security of selected Blockchains. We may give the Customer the option to Stake their
											Cryptocurrencies with Our Validator nodes on certain Blockchains. For Staking Cryptocurrencies Users are
											eligible to receive a certain amount of Staking Rewards. Rewards are never paid by the Validator, but by
											the respective Blockchain distribution module. SG-1 Validators may deduct a certain amount of commission
											from accrued Rewards as a charge for their Services. The commission rate can be changed by SG-1 Validators
											at any time without further notice. Rewards may fluctuate to a great extent and frequency as they directly
											depend on several factors including but not limited to the amount of Staked Cryptocurrencies on the
											network.
										</span>
									</p>
									<p className='description'>&nbsp;</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>2.1.2. Unbonding Time</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											Delegated Cryptocurrencies may inherit a certain Unbonding Time given by the specific set of rules of the
											specific Blockchain. We want to call attention to the fact that during the Unbonding Time (which is set to
											different time frames by different Blockchains), Users will not be able to use, send or sell their
											Cryptocurrencies. SG-1 Validators have no influence over these Unbonding Times and shall not be held
											responsible for any losses, or other issues caused by the Customer Unbonding their Cryptocurrencies.
										</span>
									</p>
									<p className='description'>&nbsp;</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>2.1.3. Slashing Risk</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											SG-1 Validators always work with the intention of doing their best in their operations and for their
											Customers. Nevertheless, there is always a minimal risk of Slashing. Slashing occurs if a Validator’s
											hardware or software inherits sudden errors and the operations are shut down unexpectedly and without
											further actions of the Validator. After a certain amount of time (“Slashing Window”) the Validator gets
											Jailed (which means that the Validator is inactive) and a certain amount of the Delegated Cryptocurrencies
											will be Slashed. Furthermore no more Rewards will be paid to Delegators of the Slashed Validator (as long
											as the Validator is inactive). The Slashing fraction depends on the certain Blockchain ruleset; “Slashed”
											percentages of the Delegated Stakes are not revocable and will not be refunded by SG-1 Validators if any
											Slashing occurs. To minimise the risk of Slashing SG-1 Validators facilitate multiple fallback levels,
											sophisticated monitoring and incident response systems. SG-1 Validators shall not be held liable for
											double signing, being Jailed, inactiveness or the occurrence of Slashing in any form. The risk hereby lies
											with the Customer in choosing the Validator of their trust for Delegating their Cryptocurrencies.&nbsp;
										</span>
									</p>
									<p className='description'>&nbsp;</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>2.1.4. Governance Decisions</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											A Validator can vote with their full pool of Delegations (Voting Power) on any Governance Proposal on a
											Blockchain. SG-1 Validators are dedicated to research and discuss each Governance Proposal to meet a
											decision in the best interest of their Delegators. If a Delegator is not aligned with the decision of
											their Validator on a certain Proposal, they can override the vote of the Validator by voting by themself.
											SG-1 Validators shall not be held responsible for any voting decision or outcome of any Proposal on any
											Blockchain. The Governance vote of the Customer solely lies in their responsibility. Voting on behalf of
											Delegators is merely a service provided by SG-1 Validators and can be overruled by the Customer at any
											time. While frequent and active Governance participation is one of Our key visions, SG-1 Validators are
											not obligated to vote on any Proposal and cannot be held responsible for not voting.
										</span>
									</p>
									<p className='description'>&nbsp;</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>2.2.&nbsp; IBC Relaying</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											SG-1 Validators provide the hardware and software to relay IBC (Internet of Blockchains) transactions. IBC
											transactions are transactions of data packets that are facilitated by Relayers connecting specific
											Blockchains via inter-blockchain communication channels. SG-1 Validators maintain and manage some of these
											IBC channels. An IBC transaction will automatically be fulfilled by the fastest operator, SG-1 Validators
											therefore have limited power over which transactions will be relayed by Our Relayer and which will not.
											SG-1 Validators may charge a transaction fee from the Customer for processing IBC transactions. SG-1
											Validators shall not be held responsible for any IBC transactions that might be faulty, might not be
											facilitated or might not be executed and will not refund any losses or damages caused by IBC transactions.
										</span>
									</p>
									<p className='description'>&nbsp;</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>2.3. Infrastructure Services</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											We offer access to various data exchange interfaces (like RPC, LCD, REST-API, gRPC or JSON-HTTP endpoints)
											which may record Your IP address, store it (for up to a year) and analyse it to maintain effectiveness of
											those systems. These Services have to be called via dedicated and explicit subdomains of Our Website.
										</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											Data exchange interfaces are only meant to be used by experienced Customers with a background of coding
											knowledge. By using these Services You agree on having Your IP address data stored for above stated
											purposes. If You dissent in having Your IP address stored and would like Us to delete Your IP address
											please contact Our Customer support at{" "}
										</span>
										<a href='mailto:info@sg-1.online'>
											<span style={{ fontWeight: "400px" }}>info@sg-1.online</span>
										</a>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											SG-1 Validators shall not be held liable for incorrect information, corrupted or false data exhibited,
											processed or used on the Infrastructure Services or any other losses of funds, losses of revenues, losses
											of Rewards or delays etc. caused by all or parts of Our Website, Our Services or Our contents. The illegal
											use or use with malicious intent of SG-1 Infrastructure and/or Services is strictly prohibited.
										</span>
									</p>
									<p className='description'>&nbsp;</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>2.4. Website &amp; Services</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											SG-1 Validators cannot be held liable for the accuracy, truthfulness or reliability of any information
											provided on Our Website, or by Our Services and reserves the right to change, modify, add or remove parts
											of Our Website and/or Our Services, Our Terms and Conditions as well as Our Privacy Policy at any time
											without further notice.
										</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											SG-1 Validators may provide outgoing links to third party websites on their website, including but not
											limited to various social media platforms such as{" "}
										</span>
										<a href='http://www.instagram.com/'>
											<span style={{ fontWeight: "400px" }}>www.instagram.com/</span>
										</a>
										<span style={{ fontWeight: "400px" }}>; </span>
										<a href='http://www.facebook.com/'>
											<span style={{ fontWeight: "400px" }}>www.facebook.com/</span>
										</a>
										<span style={{ fontWeight: "400px" }}>; </span>
										<a href='http://www.google.com/'>
											<span style={{ fontWeight: "400px" }}>www.google.com/</span>
										</a>
										<span style={{ fontWeight: "400px" }}>; </span>
										<a href='http://www.twitter.com/'>
											<span style={{ fontWeight: "400px" }}>www.twitter.com/</span>
										</a>
										<span style={{ fontWeight: "400px" }}>; </span>
										<a href='http://www.youtube.com/'>
											<span style={{ fontWeight: "400px" }}>www.youtube.com/</span>
										</a>
										<span style={{ fontWeight: "400px" }}>, </span>
										<a href='http://www.reddit.com/'>
											<span style={{ fontWeight: "400px" }}>www.reddit.com/</span>
										</a>
										<span style={{ fontWeight: "400px" }}>, </span>
										<a href='http://www.tiktok.com/'>
											<span style={{ fontWeight: "400px" }}>www.tiktok.com/</span>
										</a>
										<span style={{ fontWeight: "400px" }}> , </span>
										<a href='http://www.github.com/'>
											<span style={{ fontWeight: "400px" }}>www.github.com/</span>
										</a>
										<span style={{ fontWeight: "400px" }}> , </span>
										<a href='http://www.medium.com/'>
											<span style={{ fontWeight: "400px" }}>www.medium.com/</span>
										</a>
										<span style={{ fontWeight: "400px" }}> and </span>
										<a href='https://telegram.org/'>
											<span style={{ fontWeight: "400px" }}>https://telegram.org/</span>
										</a>
										<span style={{ fontWeight: "400px" }}> .</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											You may need to accept the specific terms and conditions as well as the privacy policies of the linked
											third party websites in order to use their content on Our Website or if You visit those websites through a
											link provided on ccvalidators.com. We do not have control over or responsibility for the content,
											policies, terms and conditions etc of those third party websites.
										</span>
									</p>
									<p className='description'>&nbsp;</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>2.5. Support</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											We have a dedicated support team which You can contact anytime by email via{" "}
										</span>
										<a href='mailto:support@ccvalidators.com'>
											<span style={{ fontWeight: "400px" }}>support@ccvalidators.com</span>
										</a>
										<span style={{ fontWeight: "400px" }}>, </span>
										<a href='mailto:info@sg-1.online'>
											<span style={{ fontWeight: "400px" }}>info@sg-1.online</span>
										</a>
										<span style={{ fontWeight: "400px" }}>
											. We will answer every legitimate email as soon as We can. We reserve the right to not answer any emails
											which seemingly contain spam, phishing, financial advice, harmful or other dubious content.
										</span>
									</p>
									<p className='description'>&nbsp;</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>2.6. Availability&nbsp;</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											SG-1 Validators cannot warrant any availability of Our Website or Our Services at any time. We may have to
											update, monitor, redesign or modify etc. parts or all of Our Website and/or Our Services. Furthermore You
											accept that SG-1 Validators shall not be held liable for any losses, damages or inconveniences due to the
											unavailability of all or parts of Our Website or Our Services. SG-1 Validators reserves the right to
											modify or cancel all Our Services and/or shut down Our operations completely at any time without further
											notice or reasoning and without being held liable for any damages, losses or inconveniences resulting from
											it.&nbsp; We are not obliged to provide any updates, releases or corrections of Our existing content,
											Website, Services or other.
										</span>
									</p>
									<p className='description'>&nbsp;</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>2.7. Disclaimer</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											Any information provided on Our Website, through Our social media profiles (or channels) or by Our
											Services shall never be understood as financial advice. You and You only are responsible for the financial
											choices You make. SG-1 Validators cannot be held responsible for any purchases or losses made by the
											Customer.&nbsp;&nbsp;
										</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											SG-1 Validators cannot be held liable for the accuracy of any information provided on social media posts,
											posts on third-party websites, as guest appearance in third-party events online or via direct
											communication or marketing material such as graphics, videos, flyers, folders, posters, stickers, etc.
										</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											SG-1 Validators, their affiliates and their employees cannot be held liable for any damages, claims or
											losses resulting from You accessing Our Website and/or Our Services or any other issues related to all or
											parts of the offered Services, Website or content provided by SG-1 Validators at any time.
										</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											SG-1 Validators may provide links to third-party websites or content. SG-1 Validators cannot be held
											responsible for any content or services provided on those third-party websites. Furthermore SG-1
											Validatros cannot be held liable for any losses caused directly or indirectly by contents or services
											available on those third-party websites.
										</span>
									</p>
									<p className='description'>&nbsp;</p>
									<p className='h4 title'>
										<b>3. Obligations of the Customer&nbsp;</b>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											By using Our Website and/or any of Our Services, You agree to take the following actions and agree to
											carry any liability, responsibility and accountability as described hereafter.
										</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											You hereby confirm that You are legally allowed to use Our Services by law or regulation in Your country
											of jurisdiction and that Your usage does not subject Us to any registration requirement within Your
											country or jurisdiction. By using Our Services and/or Our Website You are solely responsible for
											compliance with local laws. You agree that You will not use any of Our Services, Our Website, Our software
											or Our hardware for any illegal, unauthorised or malicious purposes. You agree that You comply with Our
											Terms and Conditions and confirm that this agreement will not breach or violate any other agreement or
											contract You are currently in. By using Our Services and/or Our Website You warrant that You are not
											subject to any list of economic sanctions, any trade embargo or other trade restrictions or prohibitions.
										</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											You and You only are responsible for paying taxes for Your Cryptocurrencies, Your Staked Cryptocurrencies,
											Your claimed Staking Rewards as well as Your earnings from Cryptocurrencies and the custody of Your
											Cryptocurrencies according to the applicable local and national laws Sof Your country of jurisdiction.
										</span>
									</p>
									<p className='description'>&nbsp;</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>3.1. Staking</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											It is solely Your decision if and how many of Your Cryptocurrencies as well as how much of Your
											Cryptocurrency will be Staked with SG-1 Validators. You and only You are in full control of the ways You
											use Your Cryptocurrencies as well as where, how and if You decide to use a Staking Service or any other
											Service provided by SG-1 Validators.You take full responsibility for taking care and adjusting Your
											actions in case operations or Services change or any events occur including but not limited to Slashing,
											downtime of the Validator Nodes, current unavailability of Services, change of Commission, extreme
											fluctuation of Rewards, halted Blockchains, etc.&nbsp;
										</span>
									</p>
									<p className='description'>&nbsp;</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>3.2. IBC Transactions</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											You and You only are responsible for Your correct use of Your IBC transactions. SG-1 Validators will not
											be held liable for or refund any IBC transactions. SG-1 Validators will also not be held liable for
											duration and speed of transactions, availability of Service or other issues concerning the offered IBC
											Services by SG-1 Validators. You consent to SG-1 Validators charging a transaction fee for IBC
											transactions if SG-1 Validators implement said fees as default. It lies at Your sole discretion as to
											whether and how many of Your Cryptocurrencies as well as how much of Your Cryptocurrency You decide to
											transfer via IBC Relaying.
										</span>
									</p>
									<p className='description'>&nbsp;</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>3.3. Infrastructure Services&nbsp;</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											By using any or part of any of Our offered Infrastructure Services You acknowledge that SG-1 Validators
											cannot be held responsible for displaying, processing or using incorrect data in any way. As downtime or
											liveliness issues of various IT infrastructure can happen at any given time, SG-1 Validators will not be
											held responsible for any losses or damages related to unexpected behaviour of such Services. You confirm
											that You will not use SG-1 infrastructure Services for any illegal purposes or with malicious intent.
										</span>
									</p>
									<p className='description'>&nbsp;</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>3.4. Prohibited Actions</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											You hereby confirm that You will not disrupt, interfere with, access without authority or damage any part
											of Our Services and/or Our Website, any hardware and/or software and/or network and/or equipment used in
											the provision of Our Services and/or Our Website or any hardware and/or software and/or network and/or
											equipment used or owned by any third party. You agree that You will not take any steps to harm Our ability
											to provide Our Services, Our systems and/or Our operations in any form.&nbsp;
										</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											Any unauthorised attempt at compromising Our hardware and/or Our software or any other action inhibiting
											or altering the functionality of Our technical equipment, Our Services, Our code, Our hardware or Our
											software including but not limited to attempting to access or using Our private keys unauthorised,
											distributed denial-of-service attacks (DDos) and malware attacks is strictly prohibited and considered a
											violation of these Terms and Conditions.
										</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											You also confirm that You will not spread any misinformation regarding Our company, Our employees, Our
											Services, Our Website and/or Our operations.
										</span>
									</p>
									<p className='description'>&nbsp;</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>3.5. General Risks</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											You hereby acknowledge that any usage of Blockchains, Cryptocurrencies and services related to Blockchains
											and Cryptocurrencies inherits general risks and limitations such as malicious attacks, falsely addressed
											transactions, scams, hacks, phishing, vulnerable coding, badly designed systems and highly experimental
											software etc. Any usage of Blockchains, Cryptocurrencies and services related to Blockchains and
											Cryptocurrencies by a Customer takes place at their own risk. You acknowledge that SG-1 Validators cannot
											be held liable for any losses or damages caused by financial decisions by the Customer, malfunctioning of
											Services, malfunctioning of hardware or software, third party services, malfunctioning of Blockchain
											networks, accidental double signing, Slashing, vulnerable coding, human error, scams, malicious attacks or
											badly designed systems.&nbsp;
										</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											You acknowledge the fact that any linked third-party websites or services inherit their own privacy
											policy, terms of conditions and terms of use over which SG-1 has no power and which You should read
											carefully and accept before using said third-party websites or services. You acknowledge that SG-1
											Validators cannot be held liable for any losses caused directly or indirectly by contents or services
											available on those third-party websites.
										</span>
									</p>
									<p className='description'>&nbsp;</p>
									<p className='h4 title'>
										<b>4. Intellectual Property</b>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>4.1. Our Contents</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											All contents displayed or used on Our Website, Our Services and/or published via social media platforms as
											well as third-party websites or services including but not limited to Our graphics, texts, logos, videos,
											gifs, website designs, audio, code and software are protected by copyright laws, may be protected by
											trademark laws and are owned by SG-1 Validators. Provided that You have gained authorised access, You may
											use the content or parts of the content of Our Website and Services for personal, non-commercial use only
											unless otherwise stated by Us. Without explicit written permission by Us it is strictly prohibited to
											copy, sell, reupload, translate, transmit, licence, post, reproduce or similarly handle any part of Our
											contents displayed or used on Our Website, Our Services and/or Our content published via social media
											platforms as well as Our content published on third party websites or services including but not limited
											to graphics, texts, logos, videos, gifs, website designs, audio, code and software etc.
										</span>
									</p>
									<p className='description'>&nbsp;</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>4.2. Customer Contents</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											You acknowledge and agree that all contents including but not limited to texts and graphics (via email,
											personal messages, comments, phone calls, ideas etc.) submitted by You to Us shall become exclusively
											owned by Us including all intellectual property rights and can be unrestrictedly used by Us without
											compensation or referral to You. You hereby relinquish all rights to such content and guarantee that You
											have the right to the content You hand in to Us and that there shall be no liability concerning
											infringement or any type of claim against SG-1 Validators.&nbsp;
										</span>
									</p>
									<p className='description'>&nbsp;</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>4.3. Software &amp; Code</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>
											Without Our explicit written permission or explicit declaration in these Terms, it is strictly prohibited
											to copy, sell, reupload, translate, transmit, licence, post, reproduce or similarly handle any part of Our
											content, including but not limited to Our Services, Our Website, Our software and/or Our code. Any
											open-source code is governed by its specific associated licence. All contents are provided as they are and
											shall be used for informational and personal purposes only.&nbsp;
										</span>
									</p>
									<p className='description'>&nbsp;</p>
									<p className='h4 title'>
										<b>5. Other important terms</b>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>5.1.</span>
										<span style={{ fontWeight: "400px" }}>
											{" "}
											If any court or relevant authority decides that any paragraph of these Terms and Conditions is unlawful,
											the remaining paragraphs will remain in full force and
										</span>
										<span style={{ fontWeight: "400px" }}> will not affect the validity and enforceability of any remaining</span>
										<span style={{ fontWeight: "400px" }}>
											{" "}
											paragraph as each of the paragraphs of these Terms and Conditions operate separately.&nbsp;
										</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>5.2.</span>
										<span style={{ fontWeight: "400px" }}>
											{" "}
											Any affairs relating to Our Terms and Conditions, including but not limited to the execution, alteration,
											interpretation, resolution and validity of this agreement shall apply to the laws of the{" "}
										</span>
										<span style={{ fontWeight: "400px" }}>Republic of Austria</span>
										<span style={{ fontWeight: "400px" }}>.</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>5.3.</span>
										<span style={{ fontWeight: "400px" }}>
											{" "}
											We may transfer Our rights and obligations under these Terms and Conditions to another third party with
											whom We have a legal relationship with at any time.
										</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>5.4.</span>
										<span style={{ fontWeight: "400px" }}>
											{" "}
											Even if We do not insist on enforcing Our rights under these Terms and Conditions immediately, We can
											still enforce Our rights at a later date.
										</span>
									</p>
									<p className='description'>
										<span style={{ fontWeight: "400px" }}>5.5.</span>
										<span style={{ fontWeight: "400px" }}>
											{" "}
											CryptoCrewValidators, their affiliates, their employees or any of their service providers shall not be
											held liable to You or any third party for any damages, losses or impairments of any kind, including but
											not limited to unavailability, discontinued service, malfunction of hardware or software, human errors,
											loss of business, loss of profits, loss of revenue, loss of data, corruption of data, double signing,
											Slashing, unclaimed Staking Rewards, missing Staking Rewards, missing funds, stuck transactions, failed
											transactions, inaccurate information, damages hardware or software (e.g. because of an earthquake or any
											other force majeure), incompatibility of systems or informations, breach of contract, personal damage,
											Governance votes, Governance Proposals, losses because of Unbonding Time, halted Blockchains, not reached
											consensus, Blockchains or smart contracts provided or used by third parties, Blockchains or smart
											contracts we offer complementary Services to (including but not limited to a Validator), etc.
										</span>
									</p>
									<p className='description'>
										5.6. SG-1 Validators are not responsible and shall not be held liable for any damages, losses or impairments
										of any kind caused by any Blockchain, network or smart contract that We offer complementary Services to,
										including, but not limited to a Validator, as We have no way of verifying any information provided by those
										third parties. You are solely responsible for your decisions and actions concerning these Blockchains,
										networks and smart contracts.
									</p>
									<p className='description'>&nbsp;</p>
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
		</Layout>
	);
};

export default TermsOfService;

export const Head = () => <title>Terms of Service</title>;
